@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  background-color: #eaeaea;
}

.App {
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  padding: 0 30px;
}


.conditions {
  text-align: justify;
  margin: auto;
  max-width: 1500px;
  padding: 0 30px;
}

.button {
  font-family: Poppins, serif;
  text-transform: uppercase;
  outline: none;
  border: none;
  width: 300px;
  height: 48px;
  border-radius: 20px;
  float: right;
  background-color: #58595B;
  font-size: 23px;
  color: #eaeaea;
  margin-bottom: 30px;
}

h1 {
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
}

h2 {
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  color: #58595B;
}

p {
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

li {
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
}

.via-logo-top {
  width: 281px;
  margin: 48px;
  object-fit: contain;
}

.icons {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.mockups {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.top-container {
  position: relative;
  margin-bottom: 50%;
  width: 100%;
}

.store-button-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 60px;
}

.store-button {
  height: 5rem;
  max-height: 100px;
}

.min-sr-logo {
  margin-top: 30px;
  max-width: 429px;
  width: 45%;
  object-fit: contain;
}

.flying {
  width: 40%;
}

.item-container {
  display: flex;
  align-items: center;
}

.min-sr-logo-bottom {
    width: 100px
}

.dl-logo-bottom {
  width: 100px;
}

.item-text {
  text-align: left;
  padding: 0 60px;
  padding-bottom: 80px;
}

.bottom-icons-container {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bottom-icons-container img {
  width: 300px;
}

.logo {
  width: 200px;
  margin-top: 60px;
  object-fit: contain;
}

.bottom-text {
  max-width: 1000px;
  padding: 30px;
}

.bottom-line {
  width: 100%;
  max-width: 340px;
  height: 1px;
  background-color: #C4C4C4;
  margin: 32px;
}

.bottom-top-line {
  width: 100%;
  max-width: 463px;
  height: 1px;
  background-color: #C4C4C4;
  margin: 24px;
}

.logos-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: auto;
}

@media only screen and (max-width: 1000px) {
  .item-container {
    display: block;
  }

  .store-button {
    width: 200px;
  }

  .flying {
    width: 80%;
  }

  .icons {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .mockups {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .top-container {
    margin-bottom: 60%;
  }

}

@media only screen and (max-width: 700px) {

  .store-button {
    height: 70%;
    width: 90%;
  }

  .store-button-container {
    height: 50%;
    width: auto;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px;
    margin-top: 30px;
  }

  .bottom-icons-container {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bottom-icons-container img {
    margin-top: 30px;
  }

  .item-text {
    padding: 0;
    padding-bottom: 20px;
  }

  .logo {
    width: 60%;
    object-fit: contain;
  }

  .via-logo-top {
    width: 200px;
  }

  .logos-top {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .min-sr-logo {
    width: 70%;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 40px;
  }

  p {
    font-size: 20px;
  }

  .logo {
    width: 15%;
    object-fit: contain;
  }

  .bottom-icons-container img {
    margin-top: 8%;
    width: 80%;
  }

  .bottom-top-line {
    margin: 3%;
    width: 60%;
  }

  .bottom-line {
    margin: 3%;
    width: 40%;
  }
}

@media only screen and (max-width: 500px) {

  .via-logo-top {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 40%;
  }

  .min-sr-logo:first-child {
    margin-top: 60px;
    margin-bottom: 10px;
    width: 60%;
  }

  .min-sr-logo:last-child {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 55%;
  }

  .mockups {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  h1 {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  .App {
    padding: 0 17px;
  }

  .bottom-text {
    padding: 0;
  }

}
